import CommandRepositoryInterface from './CommandRepositoryInterface'
import MethodNotImplemented from '../exception/MethodNotImplemented'
import { Nullable } from '@/core/domain/type/types'

export default abstract class AbstractCommandRepository implements CommandRepositoryInterface
{
    delete(guid: string, payload: string[][] | object | null): Promise<any>
    {
        throw new MethodNotImplemented();
    }
}